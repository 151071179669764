import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'material-icons/iconfont/material-icons.css';

import './style/tailwind.css'

import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import 'primevue/resources/themes/aura-light-green/theme.css'
import ConfirmationService from 'primevue/confirmationservice';

import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { toast } from 'vue3-toastify';

import { createI18n } from 'vue-i18n';
import en from './locale/en.json'
import jp from './locale/jp.json'
const locale = localStorage.getItem('locale') || 'en';
const messages = {
    en,
    jp
  };
const i18n = createI18n({
  legacy : false,
  locale,
  fallbackLocale: 'en',
  messages
});

const toastOptions = {
    autoClose: 2000,
    duration : 2000,
    position: toast.POSITION.TOP_CENTER,

}
console.log('Updated instock products - 13/10/2024')
createApp(App).use(router).use(PrimeVue).use(i18n).use(Vue3Toasity , toastOptions).directive('tooltip' , Tooltip).use(ConfirmationService).mount('#app')
