<template>
  <form @submit.prevent="updateInstock">
    <p v-if="errors['instocks.product_id']" class="text-danger">
      {{ errors["instocks.product_id"][0] }}
    </p>
    <p v-if="errors['instocks.instock']" class="text-danger">
      {{ errors["instocks.instock"][0] }}
    </p>
    <div
      v-for="(record, index) in instock"
      :key="index"
      class="grid grid-cols-2 gap-4 mt-4"
    >
      <div>
        <label class="block mb-1 text-label" for="product_id">{{
          $t("labels.product")
        }}</label>
        <Dropdown
          v-model="record.product_id"
          disabled
          :options="products"
          option-label="name"
          option-value="id"
          filter
          optionLabel="name"
          placeholder="Select a product"
          class="w-full bg-white"
        />
      </div>
      <div>
        <label class="block mb-1 text-label" for="instock">{{
          $t("labels.instock")
        }}</label>
        <InputText
          v-model="record.instock"
          required
          name="instock"
          type="number"
          placeholder="instock"
          class="w-full"
        />
      </div>
    </div>
    <div class="flex col-span-2 mt-6">
      <button
        :disabled="loading"
        v-tooltip.bottom="'Create All Records'"
        class="w-full px-6 py-2 text-white rounded outline-none bg-primary ring-0"
      >
        <span v-if="loading">Loading</span>
        <span v-else>{{ $t("actions.update") }}</span>
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref, defineEmits, onMounted } from "vue";
import ApiService from "@/services/ApiService";
import { toast } from "vue3-toastify";
//prime vue components
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";

const emit = defineEmits(["created"]);

const products = ref([]);
const instock = ref([]);
const errors = ref({});
const loading = ref(false);
const updateInstock = () => {
  loading.value = true;
  const id = toast.loading("Updating, please wait . . .");
  ApiService.put("today-instocks", { instocks: instock.value })
    .then(() => {
      loading.value = false;
      toast.update(id, {
        render: "Updated instock.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false
      });
      emit("updated");
    })
    .catch((res) => {
      loading.value = false;
      toast.update(id, {
        render: "Error updating instock.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false
      });
      errors.value =
        res?.response?.data && res?.response?.data?.errors
          ? res?.response?.data?.errors
          : {};
    });
};

onMounted(async () => {
  await ApiService.get("instock-products?limit=all")
    .then((res) => {
      products.value = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
  await ApiService.get("today-instocks")
    .then((res) => {
      instock.value = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
});
</script>

<style scoped></style>
