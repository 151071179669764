<template>
  <form @submit.prevent="createInstock">
    <p v-if="errors['instocks.product_id']" class="text-danger">
          {{ errors['instocks.product_id'][0] }}
        </p>
        <p v-if="errors['instocks.instock']" class="text-danger">
          {{ errors['instocks.instock'][0] }}
        </p>
    <div
      v-for="(record, index) in instock"
      :key="index"
      class="grid grid-cols-2 gap-4 mt-4"
    >
      <div>
        <label class="block mb-1 text-label" for="product_id">{{
          $t("labels.product")
        }}</label>
        <Dropdown
          v-model="record.product_id"
          disabled
          :options="products"
          option-label="name"
          option-value="id"
          filter
          optionLabel="name"
          placeholder="Select a product"
          class="w-full bg-white"
        />
      </div>
      <div>
        <label class="block mb-1 text-label" for="instock">{{
          $t("labels.instock")
        }}</label>
        <InputText
          v-model="record.instock"
          required
          name="instock"
          type="number"
          placeholder="instock"
          class="w-full"
        />
      </div>
      <!-- <div class="grid items-end h-full grid-cols-2 gap-2">
        <span
         v-tooltip.bottom="'Add More Records'"
          class="flex items-center justify-center px-3 py-2 text-white rounded outline-none cursor-pointer bg-success ring-0"
          @click="addMore"
        >
        <span class="material-icons-outlined">add</span>
        </span>
        <span
          v-if="index != 0"
           v-tooltip.bottom="'Remove Record'"
          class="flex items-center justify-center px-3 py-2 text-white rounded outline-none cursor-pointer bg-label ring-0"
          @click="remove(index)"
        >
          <span class="material-icons-outlined">clear</span>
        </span>
      </div> -->
    </div>
    <div class="flex col-span-2 mt-6">
      <button
        v-tooltip.bottom="'Create All Records'"
        :disabled="loading"
        class="w-full px-6 py-2 text-white rounded outline-none bg-primary ring-0"
      >
        <span v-if="loading">Loading</span>
        <span v-else>{{ $t('actions.create') }}</span>
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref, defineEmits, onMounted } from "vue";
import ApiService from "@/services/ApiService";
import { toast } from "vue3-toastify";
//prime vue components
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";

const emit = defineEmits(["created"]);

const products = ref([]);
const instock = ref([]);
const latestInstocks = ref([]);
const errors = ref({});
const loading = ref(false);

const createInstock = () => {
  loading.value = true;
  const id = toast.loading("Creating, please wait . . .");
  ApiService.post("instocks", {instocks : instock.value})
    .then(() => {
      loading.value = false;
      toast.update(id, {
        render: "Created instock.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false
      });
      emit("created");
    })
    .catch((res) => {
      loading.value = false;
      toast.update(id, {
        render: "Error creating instock.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false
      });
      errors.value =
        res?.response?.data && res?.response?.data?.errors
          ? res?.response?.data?.errors
          : {};
    });
};

onMounted(async () => {
  await ApiService.get("instock-products?limit=all")
    .then((res) => {
      products.value = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
    await ApiService.get("latest-instocks")
    .then((res) => {
      latestInstocks.value = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
    products.value.forEach((product) => {
      let record = latestInstocks.value.find((r) => r.product_id == product.id);
      let obj = {
        product_id : product.id,
        instock : record ? record.instock : 0
      }
      instock.value.push(obj)
    })
});
</script>

<style scoped></style>
