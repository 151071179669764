<template>
    <form @submit.prevent="updateRule">
        <div class="grid grid-cols-2 gap-4 mb-6">
            <div>
                <label class="block mb-1" for="title">Title</label>
                <InputText
                    v-model="rule.title"
                    name="title"
                    type="text"
                    placeholder="product title"
                    class="w-full"
                    />      
                    <p v-if="errors.title" class="text-sm text-danger">{{ errors.title[0] }}</p>          
            </div>
            <div>
                <label class="block mb-1" for="sub_title">Sub Title</label>
                <InputText
                    v-model="rule.sub_title"
                    name="sub_title"
                    type="text"
                    placeholder="product sub_title"
                    class="w-full"
                    />      
                    <p v-if="errors.sub_title" class="text-sm text-danger">{{ errors.sub_title[0] }}</p>          
            </div>
        </div>
        <div>
            <label for="body" class="block mb-1">Paragraph</label>
            <quill-editor
                v-model:value="rule.body"
            />
        </div>
        <div class="flex justify-end mt-6">
            <button :disabled="loading" class="px-6 py-2 text-white rounded outline-none bg-secondary ring-0">
                <span v-if="loading">Loading</span>
                <span v-else>{{ $t('actions.update') }}</span>
            </button>
        </div>
    </form>
</template>

<script setup>
import { ref , defineEmits , defineProps, onMounted} from 'vue';
import ApiService from '@/services/ApiService';
import { toast } from 'vue3-toastify';
import { useRoute } from 'vue-router';

//prime vue components
import InputText from 'primevue/inputtext';

import { quillEditor } from 'vue3-quill'

const props = defineProps({
    rule : Object
})

const emit = defineEmits(['created']);

const route = useRoute();
const rule = ref({
    id : '',
    title : '',
    sub_title : '',
    body : '',
    product_id : route.params.id
})
const errors = ref({});
const loading = ref(false);

const updateRule = () => {
    loading.value = true;
    const id = toast.loading('Updating, please wait . . .');
    ApiService.put(`rules/${rule.value.id}` , rule.value).then(() => {
        loading.value = false;
        toast.update(id, {
            render: "Updated rule.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        emit('updated');
    }).catch((res) => {
        loading.value = false;
        toast.update(id, {
            render: "Error updating rule.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
        errors.value = res?.response?.data && res?.response?.data?.errors ? res?.response?.data?.errors : {};
    })
}

onMounted(() => {
    rule.value.id = props.rule.id;
    rule.value.title = props.rule.title;
    rule.value.sub_title = props.rule.sub_title;
    rule.value.product_id = props.rule.product_id;
    rule.value.body = props.rule.body;
})
</script>

<style scoped>
.ql-container {
    min-height: 200px;
}
</style>