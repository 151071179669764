<template>
  <form @submit.prevent="updateInstock">
    <div>
      <label class="block mb-1 text-label" for="date">{{
        $t("labels.date")
      }}</label>
      <Calendar
        @update:modelValue="convertDate"
        v-model="date"
        placeholder="01-01-2024"
        class="w-full border-success"
      />
    </div>
    <div class="mt-4">
      <label class="block mb-1 text-label" for="product_id">{{
        $t("labels.product")
      }}</label>
      <Dropdown
        v-model="instock.product_id"
        :options="products"
        option-label="name"
        option-value="id"
        filter
        optionLabel="name"
        placeholder="Select a product"
        class="w-full"
      />
      <p v-if="errors.product_id" class="text-sm text-danger">
        {{ errors.product_id[0] }}
      </p>
    </div>
    <div class="mt-4">
      <label class="block mb-1 text-label" for="instock">{{
        $t("labels.instock")
      }}</label>
      <InputText
        v-model="instock.instock"
        name="instock"
        type="number"
        placeholder="instock"
        class="w-full"
      />
      <p v-if="errors.instock" class="text-sm text-danger">
        {{ errors.instock[0] }}
      </p>
    </div>
    <div class="flex justify-end mt-6">
      <button
        :disabled="loading"
        class="px-6 py-2 text-white rounded outline-none bg-primary ring-0"
      >
        <span v-if="loading">Loading</span>
        <span v-else>{{ $t('actions.update') }}</span>
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref, defineEmits, onMounted, defineProps } from "vue";
import ApiService from "@/services/ApiService";
import { toast } from "vue3-toastify";
//prime vue components
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";

const props = defineProps({
  instock: Object
});

const emit = defineEmits(["edited"]);

const products = ref([]);
const date = ref("");
const instock = ref({
  product_id: "",
  instock: "",
  date: ""
});
const errors = ref({});
const loading = ref(false);

const convertDate = (dateString) => {
  if (dateString == null) {
    return;
  }
  const myanmarDate = new Date(dateString);
  const year = myanmarDate.getFullYear();
  const month = myanmarDate.getMonth() + 1;
  const day = myanmarDate.getDate();
  const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
  instock.value.date = formattedDate;
};

const updateInstock = () => {
  loading.value = true;
  const id = toast.loading("Updating, please wait . . .");
  ApiService.put(`instocks/${instock.value.id}`, instock.value)
    .then(() => {
      loading.value = false;
      toast.update(id, {
        render: "Updated instock.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false
      });
      emit("edited");
    })
    .catch((res) => {
      loading.value = false;
      toast.update(id, {
        render: "Error updating instock.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false
      });
      errors.value =
        res?.response?.data && res?.response?.data?.errors
          ? res?.response?.data?.errors
          : {};
    });
};

onMounted(() => {
  instock.value = props.instock;
  date.value = new Date(props.instock.date);
  ApiService.get("instock-products?limit=all")
    .then((res) => {
      products.value = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
});
</script>

<style scoped></style>
