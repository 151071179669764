<template>
    <div class="p-2">
        <div class="flex items-center justify-between px-1 mb-6">
            <div class="flex items-center gap-3">
                <router-link :to="{name : 'ProductsView'}">
                    <span class="material-icons-round">west</span> 
                </router-link>
                <h1 class="text-2xl">{{ product.name }} - Rules & Regulations</h1>
            </div>
            <Button v-tooltip.left="'Create New Rule'" @click="creating = true" class="text-white border-0 bg-secondary" :label="$t('actions.create')" size="small" />
        </div>
        <LoadingIndi v-if="loading" />
        <Accordion v-else class="relative border rounded">
            <AccordionTab v-for="rule , index in product.rules" :key="rule.id" class="relative" >
                <template #header>
                    <h1 v-tooltip.bottom="'Click to see details'" class="text-gray-700">{{ index+1 }}. {{ rule.title }} - {{ rule.sub_title }}</h1>
                </template>
                <button v-tooltip.bottom="'Copy Rule'" @click="copyRule(`rule${rule.id}`)" class="absolute flex items-center justify-center p-2 text-white rounded-full right-4 bg-success hover:bg-success/90">
                    <span style="font-size: 1.4rem;" class="material-icons-round">content_copy</span>
                </button>
                <p :id="`rule${rule.id}`" v-html="rule.body" class="px-4"></p>
                <div class="flex justify-end gap-2 px-4 mt-2">
                    <button v-tooltip.bottom="'Edit Rule'" @click="editRule(rule)" class="flex items-center justify-center p-2 text-white rounded-full bg-primary hover:bg-primary/90">
                        <span style="font-size: 1.4rem;" class="material-icons-round">edit</span>
                    </button>
                    <button v-tooltip.bottom="'Delete Rule'" @click="deleteRule(rule.id)" class="flex items-center justify-center p-2 text-white rounded-full bg-label hover:bg-label/90">
                        <span style="font-size: 1.4rem;" class="material-icons-round">delete</span>
                    </button>
                </div>
            </AccordionTab>
        </Accordion>

        <!-- create rule -->
        <Dialog v-model:visible="creating" modal header="Create Product Rule" :style="{ width: '50rem' }">
            <CreateRule @created="refresh" />
        </Dialog>
        <!-- details -->
        <Dialog v-model:visible="editing" modal header="Edit Product Rule" :style="{ width: '50rem' }">
            <EditRule :rule="editingRule" @updated="refresh" />
        </Dialog>

    </div>
</template>

<script setup>
import { ref , onMounted } from 'vue';
import ApiService from '@/services/ApiService';
import { useRoute } from 'vue-router';
import { toast } from 'vue3-toastify';

import LoadingIndi from '@/components/LoadingIndi.vue';
import CreateRule from '@/components/products/rules/CreateRule.vue'
import EditRule from '@/components/products/rules/EditRule.vue'

//primevue component imports
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

const route = useRoute()
const product = ref({});
const loading = ref(false);
const creating = ref(false);
const editing = ref(false);
const editingRule = ref({});

const copyRule = (rule) => {
    let para = document.getElementById(rule);
    navigator.clipboard.writeText(para.innerText)
    toast.success("Copied product rule.")
}

const editRule = (rule) => {
    editingRule.value = rule;
    editing.value = true;
}

const refresh = () => {
    creating.value = false;
    editing.value = false;
    fetchData();
}

const deleteRule = (id) => {
    const toastId = toast.loading('Deleting, please wait . . .');
    ApiService.delete(`rules/${id}`).then(() => {
        toast.update(toastId, {
            render: "Deleted rule.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        refresh();
    }).catch(() => {
        toast.update(toastId, {
            render: "Error deleting rule.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
    })

}

const fetchData = () => {
    loading.value = true;
    ApiService.get(`rules/${route.params.id}`).then((res) => {
        console.log(res);
        product.value = res.data;
        loading.value = false;
    }).catch(() => {
        loading.value = false;
    })
}

onMounted(() => {
    fetchData();
})

</script>

<style scoped>

</style>