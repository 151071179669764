<template>
    <div>
        <LoadingIndi v-if="loading" />
        <DataTable v-else :value="products" tableStyle="min-width: 50rem">
            <template #header>
                <div class="flex justify-between">
                    <h1 class="text-2xl font-normal">Instock Products</h1>
                    <Button v-tooltip.left="'Create New Product'" @click="createForm = true" class="text-white border-0 bg-secondary" :label="$t('actions.create')" size="small" />
                </div>
            </template>
            <Column field="id" :header="$t('labels.id')"></Column>
            <Column field="name" :header="$t('labels.name')"></Column>
            <Column field="custom" :header="$t('labels.actions')">
                <template #body="slotProps">
                    <div class="relative flex gap-2 text-white">
                        <button v-tooltip.bottom="'Edit Product'" @click="showEditForm(slotProps.data.id)" class="flex items-center justify-center p-2 text-sm rounded-full bg-secondary">
                            <span style="font-size: 1.2rem;" class="material-icons-round">edit</span>
                        </button>
                        <button v-tooltip.bottom="'Delete Product'" @click="deleteProduct(slotProps.data.id)" class="flex items-center justify-center p-2 text-sm rounded-full bg-danger">
                            <span style="font-size: 1.2rem;" class="material-icons-round">delete</span>
                        </button>
                    </div>
                </template>
            </Column>
        </DataTable>

        <!-- Create Form -->
        <Dialog v-model:visible="createForm" modal header="Create Product" :style="{ width: '30rem' }">
            <CreateProduct @created="refreshData" />
        </Dialog>

        <!-- Edit Form -->
        <Dialog v-model:visible="editForm" modal header="Edit Product" :style="{ width: '30rem' }">
            <EditProduct :id="editId" @edited="refreshData" />
        </Dialog>
        
    </div>
</template>

<script setup>
import { ref , onMounted } from 'vue';
import ApiService from '@/services/ApiService';
import { toast } from 'vue3-toastify';

import CreateProduct from '@/components/instock_products/CreateProduct.vue'
import EditProduct from '@/components/instock_products/EditProduct.vue';
import LoadingIndi from '@/components/LoadingIndi.vue';

//primevue component imports
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

const loading = ref(true);
const products = ref([]);
const createForm = ref(false);
const editForm = ref(false);
const editId = ref(0);


const refreshData = async () => {
    window.location.reload();
}

const showEditForm = (id) => {
    editId.value = id;
    editForm.value = true;
}

const deleteProduct = (id) => {
    const tid = toast.loading('Deleting, please wait . . .');
    ApiService.delete(`instock-products/${id}`).then(async () => {
        toast.update(tid, {
            render: "Deleted product.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        await refreshData();
    }).catch(() => {
        toast.update(tid, {
            render: "Error deleting product.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
    })
}

onMounted(async () => {
    loading.value = true;
    ApiService.get('instock-products').then((res) => {
        products.value = res.data
        loading.value = false;
    }).catch(() => {
        loading.value = false;
    })
})

</script>

<style scoped>

</style>