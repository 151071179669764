<template>
  <div class="parent">
    <Sidebar v-model:visible="showSidebar">
        <template #header>
            <h1 class="text-lg font-bold text-center uppercase border-b text-primary">
                OOAK
            </h1>
        </template>
        <Divider align="center" type="solid">
        <b class="text-gray-700">Orders</b>
      </Divider>
      <ul class="p-1 text-gray-700">
        <li v-for="item in items" :key="item.name">
          <router-link
            :to="{ name: item.ref, query: { page: 1, ...item.query } }"
            class="flex items-center font-[Inter] w-full gap-2 py-2.5 pl-3 text-sm rounded-md hover:bg-gray-100"
            active-class="text-white bg-primary hover:bg-primary hover:text-gray-200"
            @click="showSidebar = false"
          >
            <span style="font-size: 1.3rem" class="material-icons-outlined">{{
              item.icon
            }}</span>
            <span>{{ $t(item.name) }}</span>
          </router-link>
        </li>
      </ul>
      <Divider align="center" type="solid">
        <b class="text-gray-700">Instocks</b>
      </Divider>
      <ul class="p-1 text-gray-700">
        <li v-for="item in instockItems" :key="item.name">
          <router-link
            :to="{ name: item.ref, query: { page: 1, ...item.query } }"
            class="flex items-center w-full font-[Inter] gap-2 py-2.5 pl-3 text-sm rounded-md hover:bg-gray-100"
            active-class="text-white bg-primary hover:bg-primary hover:text-gray-200"
            @click="showSidebar = false"
          >
            <span style="font-size: 1.3rem" class="material-icons-outlined">{{
              item.icon
            }}</span>
            <span>{{ $t(item.name) }}</span>
          </router-link>
        </li>
      </ul>
      <Divider align="center" type="solid">
        <b class="text-gray-700">Reports</b>
      </Divider>
      <ul class="p-1 text-gray-700">
        <li v-for="item in reportItems" :key="item.name">
          <router-link
            :to="{ name: item.ref, query: { page: 1, ...item.query } }"
            class="flex items-center font-[Inter] w-full gap-2 py-2.5 pl-3 text-sm rounded-md hover:bg-gray-100"
            active-class="text-white bg-primary hover:bg-primary hover:text-gray-200"
            @click="showSidebar = false"
          >
            <span style="font-size: 1.3rem" class="material-icons-outlined">{{
              item.icon
            }}</span>
            <span>{{ $t(item.name) }}</span>
          </router-link>
        </li>
      </ul>

    </Sidebar>
    <div class="min-h-screen shadow sidebar">
      <h1 class="py-2 text-xl font-bold text-center uppercase border-b text-primary">
        OOAK
      </h1>
      <Divider align="center" type="solid">
        <b class="text-gray-700">{{ $t('titles.orders') }}</b>
      </Divider>
      <ul class="p-1 text-gray-700">
        <li v-for="item in items" :key="item.name">
          <router-link
            :to="{ name: item.ref, query: { page: 1, ...item.query } }"
            class="flex items-center w-full gap-2 py-2.5 pl-3 font-[Inter] text-sm rounded-md hover:bg-gray-100"
            active-class="text-white bg-primary hover:bg-primary hover:text-gray-200"
          >
            <span style="font-size: 1.3rem" class="material-icons-outlined">{{
              item.icon
            }}</span>
            <span>{{ $t(item.name) }}</span>
          </router-link>
        </li>
      </ul>
      <Divider align="center" type="solid">
        <b class="text-gray-700">{{ $t('titles.instocks') }}</b>
      </Divider>
      <ul class="p-1 text-gray-700">
        <li v-for="item in instockItems" :key="item.name">
          <router-link
            :to="{ name: item.ref, query: { page: 1, ...item.query } }"
            class="flex items-center w-full gap-2 py-2.5 pl-3 font-[Inter] text-sm rounded-md hover:bg-gray-100"
            active-class="text-white bg-primary hover:bg-primary hover:text-gray-200"
          >
            <span style="font-size: 1.3rem" class="material-icons-outlined">{{
              item.icon
            }}</span>
            <span>{{ $t(item.name) }}</span>
          </router-link>
        </li>
      </ul>
      <Divider align="center" type="solid">
        <b class="text-gray-700">{{ $t('titles.reports') }}</b>
      </Divider>
      <ul class="p-1 text-gray-700">
        <li v-for="item in reportItems" :key="item.name">
          <router-link
            :to="{ name: item.ref, query: { page: 1, ...item.query } }"
            class="flex items-center w-full gap-2 font-[Inter] py-2.5 pl-3 text-sm rounded-md hover:bg-gray-100"
            active-class="text-white bg-primary hover:bg-primary hover:text-gray-200"
          >
            <span style="font-size: 1.3rem" class="material-icons-outlined">{{
              item.icon
            }}</span>
            <span>{{ $t(item.name) }}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div
      class="bg-primary shadow-lg text-white justify-between m-2 rounded px-2 flex items-center header h-[50px]"
    >
     <div class="flex items-center gap-3">
      <button class="lg:hidden" @click="showSidebar = true">
        <span style="font-size: 2rem;margin-top: 3px;" class="material-icons-round">menu</span>
      </button>
      <Dropdown @update:modelValue="changeLang" v-model="lang" :options="langOptions" class="items-center h-9" />
     </div>
      <div class="flex items-center gap-3">
        <button @click="toggle">
          <svg
            width="32px"
            height="32px"
            viewBox="0 0 61.7998 61.7998"
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <title></title>
              <g data-name="Layer 2" id="Layer_2">
                <g data-name="—ÎÓÈ 1" id="_ÎÓÈ_1">
                  <circle
                    cx="30.8999"
                    cy="30.8999"
                    fill="#3655f2"
                    r="30.8999"
                  ></circle>
                  <path
                    d="M52.587 52.908a30.895 30.895 0 0 1-43.667-.291 9.206 9.206 0 0 1 4.037-4.832 19.799 19.799 0 0 1 4.075-2.322c-2.198-7.553 3.777-11.266 6.063-12.335 0 3.487 3.265 1.173 7.317 1.217 3.336.037 9.933 3.395 9.933-1.035 3.67 1.086 7.67 8.08 4.917 12.377a17.604 17.604 0 0 1 3.181 2.002 10.192 10.192 0 0 1 4.144 5.22z"
                    fill="#677079"
                    fill-rule="evenodd"
                  ></path>
                  <path
                    d="M24.032 38.68l14.92.09v3.437l-.007.053a2.784 2.784 0 0 1-.07.462l-.05.341-.03.071c-.966 5.074-5.193 7.035-7.803 8.401-2.75-1.498-6.638-4.197-6.947-8.972l-.013-.059v-.2a8.897 8.897 0 0 1-.004-.207c0 .036.003.07.004.106z"
                    fill="#f9dca4"
                    fill-rule="evenodd"
                  ></path>
                  <path
                    d="M38.953 38.617v4.005a7.167 7.167 0 0 1-.095 1.108 6.01 6.01 0 0 1-.38 1.321c-5.184 3.915-13.444.704-14.763-5.983z"
                    fill-rule="evenodd"
                    opacity="0.11"
                  ></path>
                  <path
                    d="M18.104 25.235c-4.94 1.27-.74 7.29 2.367 7.264a19.805 19.805 0 0 1-2.367-7.264z"
                    fill="#f9dca4"
                    fill-rule="evenodd"
                  ></path>
                  <path
                    d="M43.837 25.235c4.94 1.27.74 7.29-2.368 7.263a19.8 19.8 0 0 0 2.368-7.263z"
                    fill="#f9dca4"
                    fill-rule="evenodd"
                  ></path>
                  <path
                    d="M30.733 11.361c20.523 0 12.525 32.446 0 32.446-11.83 0-20.523-32.446 0-32.446z"
                    fill="#ffe8be"
                    fill-rule="evenodd"
                  ></path>
                  <path
                    d="M21.047 22.105a1.738 1.738 0 0 1-.414 2.676c-1.45 1.193-1.503 5.353-1.503 5.353-.56-.556-.547-3.534-1.761-5.255s-2.032-13.763 4.757-18.142a4.266 4.266 0 0 0-.933 3.6s4.716-6.763 12.54-6.568a5.029 5.029 0 0 0-2.487 3.26s6.84-2.822 12.54.535a13.576 13.576 0 0 0-4.145 1.947c2.768.076 5.443.59 7.46 2.384a3.412 3.412 0 0 0-2.176 4.38c.856 3.503.936 6.762.107 8.514-.829 1.752-1.22.621-1.739 4.295a1.609 1.609 0 0 1-.77 1.214c-.02.266.382-3.756-.655-4.827-1.036-1.07-.385-2.385.029-3.163 2.89-5.427-5.765-7.886-10.496-7.88-4.103.005-14 1.87-10.354 7.677z"
                    fill="#31241b"
                    fill-rule="evenodd"
                  ></path>
                  <path
                    d="M19.79 49.162c.03.038 10.418 13.483 22.63-.2-1.475 4.052-7.837 7.27-11.476 7.26-6.95-.02-10.796-5.6-11.154-7.06z"
                    fill="#434955"
                    fill-rule="evenodd"
                  ></path>
                  <path
                    d="M36.336 61.323c-.41.072-.822.135-1.237.192v-8.937a.576.576 0 0 1 .618-.516.576.576 0 0 1 .619.516v8.745zm-9.82.166q-.622-.089-1.237-.2v-8.711a.576.576 0 0 1 .618-.516.576.576 0 0 1 .62.516z"
                    fill="#e6e6e6"
                    fill-rule="evenodd"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
      </button>
      </div>
      <OverlayPanel ref="op">
        <div class="w-100rem">
          <table>
            <tr class="flex m-2">
              <td class="flex items-center">
                <span style="font-size: 1.35rem" class="material-icons-round"
                  >person</span
                >
                <span class="ml-2">{{ $t('labels.name') }}</span>
              </td>
              <td>:</td>
              <td>{{ user.name }}</td>
            </tr>
            <tr class="flex m-2">
              <td class="flex items-center">
                <span style="font-size: 1.35rem" class="material-icons-round"
                  >email</span
                >
                <span class="ml-2">{{ $t('labels.email') }}</span>
              </td>
              <td>:</td>
              <td>{{ user.email }}</td>
            </tr>
            <div class="flex m-2">
              <button
                @click="logout"
                class="flex items-center mt-2 text-danger"
              >
                <span style="font-size: 1.35rem" class="material-icons-round"
                  >logout</span
                >
                <span class="ml-2">{{ $t('actions.logout') }}</span>
              </button>
            </div>
          </table>
        </div>
      </OverlayPanel>
    </div>
    <main class="m-2 overflow-hidden shadow main">
      <RouterView />
    </main>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import Cookies from "js-cookie";
import OverlayPanel from "primevue/overlaypanel";
import Divider from "primevue/divider";
import Sidebar from "primevue/sidebar";
import Dropdown from 'primevue/dropdown';
import { useI18n } from "vue-i18n";

const { locale } = useI18n();
const router = useRouter();
const showSidebar = ref(false);

const langOptions = ref(['en' , 'jp']);
const lang = ref(localStorage.getItem('locale') ?? 'en');
const changeLang = (e) => {
  locale.value = e;
  localStorage.setItem("locale", e);
}

const date = new Date();
const year = date.getFullYear();
const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}`: date.getMonth() +  1;
// const day = date.getDate() < 10 ? `0${date.getDate()}`: date.getDate();
const today = `${month}-${year}`
const items = [
  {
    name: "titles.sources",
    icon: "ads_click",
    ref: "SourcesView",
    query: {}
  },
  {
    name: "titles.states",
    icon: "place",
    ref: "StatesView",
    query: {}
  },
  {
    name: "titles.payment_methods",
    icon: "credit_card",
    ref: "PaymentMethodsView",
    query: {}
  },
  {
    name: "titles.delivery_agents",
    icon: "local_shipping",
    ref: "DeliveryAgentsView",
    query: {}
  },
  {
    name: "titles.accept_times",
    icon: "watch_later",
    ref: "AcceptTimesView",
    query: {}
  },
  {
    name: "titles.delivery_types",
    icon: "layers",
    ref: "DeliveryTypesView",
    query: {}
  },
  {
    name: "titles.products",
    icon: "category",
    ref: "ProductsView",
    query: {}
  },
  {
    name: "titles.orders",
    icon: "shopping_cart",
    ref: "OrdersView",
    query: {
      delivery_status: ["ordered"],
      page: 1,
      perPage: 10
    }
  },
  {
    name: "titles.return_logs",
    icon: "local_shipping",
    ref: "ReturnLogs",
    query: {
      page: 1,
      perPage: 10
    }
  },
];
const instockItems = [
{
    name: "titles.instock_products",
    icon: "inventory_2",
    ref: "InstockProducts",
  },
  {
    name: "titles.instocks",
    icon: "inventory",
    ref: "InstocksView",
    query: {
      date: today
    }
  },
]
const reportItems = [
  {
    name: "titles.categories",
    icon: "donut_large",
    ref: "CategoriesView",
    query: {}
  },
  {
    name: "titles.sub_categories",
    icon: "workspaces",
    ref: "SubCategories",
    query: {}
  },
  {
    name: "titles.statuses",
    icon: "verified_user",
    ref: "StatusesView",
    query: {}
  },
  {
    name: "titles.reports",
    icon: "article",
    ref: "ReportsView",
    query: {}
  },
];

const user = JSON.parse(Cookies.get("user"));
const op = ref();
const toggle = (event) => {
  op.value.toggle(event);
};
const logout = () => {
  Cookies.remove("token");
  Cookies.remove("user");
  router.push({ name: "LoginView" });
};
</script>

<style scoped>
.parent {
  display: grid;
  grid-template-columns: 1.2fr 6fr;
  grid-template-rows: 60px 1fr;
}
.sidebar {
  grid-column: 1;
  grid-row: 1 / 3;
}
header {
  grid-column: 2;
  grid-row: 1;
}
main {
  grid-column: 2;
  grid-row: 2;
}
@media only screen and (max-width: 1024px) {
  .sidebar {
    display: none;
  }
  .parent {
    display: block;
  }
}
</style>
