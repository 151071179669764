<template>
    <form @submit.prevent="editMethod">
        <div>
            <label class="block mb-1" for="name">{{ $t('labels.name') }}</label>
            <InputText
                v-model="method.name"
                name="name"
                type="text"
                placeholder="payment method name"
                class="w-full"
                />      
                <p v-if="errors.name" class="text-sm text-danger">{{ errors.name[0] }}</p>          
        </div>
        <div class="mt-3">
            <Checkbox
                binary
                v-model="method.active" />
            <label class="ml-2" for="active">Is payment method active?</label>
            <p v-if="errors.active" class="text-sm text-danger">{{ errors.active[0] }}</p>                 
        </div>
        <div class="flex justify-end mt-6">
            <button :disabled="loading" class="px-6 py-2 text-white rounded outline-none bg-secondary ring-0">
                <span v-if="loading">Loading</span>
                <span v-else>{{ $t('actions.update') }}</span>
            </button>
        </div>
    </form>
</template>

<script setup>
import { ref , defineEmits , defineProps, onMounted } from 'vue';
import ApiService from '@/services/ApiService';
import { toast } from 'vue3-toastify';

//prime vue components
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';

const props = defineProps({
    id : Number
})
const emit = defineEmits(['edited']);

const method = ref({
    name : '',
    active : true
})
const errors = ref({});
const loading = ref(false);

const editMethod = () => {
    loading.value = true;
    const id = toast.loading('Creating, please wait . . .');
    ApiService.put(`payment-methods/${props.id}` , method.value).then(() => {
        loading.value = false;
        toast.update(id, {
            render: "Updated payment method.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        emit('edited');
    }).catch((res) => {
        loading.value = false;
        toast.update(id, {
            render: "Error updating payment method.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
        errors.value = res?.response?.data && res?.response?.data?.errors ? res?.response?.data?.errors : {};
    })
}

onMounted(() => {
    ApiService.get(`payment-methods/${props.id}`).then((res) => {
        method.value = res.data
        method.value.active = !!method.value.active
    }).catch(() => {
        toast.error("Error fetching data.");
    })
})
</script>

<style scoped>

</style>