<template>
  <form @submit.prevent="editCategory">
    <div>
      <label class="block mb-1 text-label" for="name">{{ $t('labels.name') }}</label>
      <InputText
        v-model="category.name"
        name="name"
        type="text"
        placeholder="Category name"
        class="w-full"
      />
      <p v-if="errors.name" class="text-sm text-danger">{{ errors.name[0] }}</p>
    </div>
    <div class="mt-4">
      <label class="block mb-1 text-label" for="category_id">Category</label>
      <Dropdown
        v-model="category.category_id"
        :options="categories"
        option-label="name"
        option-value="id"
        filter
        optionLabel="name"
        placeholder="Select a category"
        class="w-full"
      />
      <p v-if="errors.category_id" class="text-sm text-danger">
        {{ errors.category_id[0] }}
      </p>
    </div>
    <div class="flex justify-end mt-6">
      <button :disabled="loading" class="px-6 py-2 text-white rounded outline-none bg-secondary ring-0">
          <span v-if="loading">Loading</span>
          <span v-else>{{ $t('actions.update') }}</span>
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref, defineEmits, defineProps, onMounted } from "vue";
import ApiService from "@/services/ApiService";
import { toast } from "vue3-toastify";

//prime vue components
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import { getCategories } from "@/services/commonApis";

const props = defineProps({
  id: Number
});
const emit = defineEmits(["edited"]);

const category = ref({
  name: "",
  category_id: ""
});
const categories = ref([]);
const errors = ref({});
const loading = ref(false);

const editCategory = () => {
  loading.value = true;
  const id = toast.loading("Updating, please wait . . .");
  ApiService.put(`sub-categories/${props.id}`, category.value)
    .then(() => {
      loading.value = false;
      toast.update(id, {
        render: "Updated sub category.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false
      });
      emit("edited");
    })
    .catch((res) => {
      loading.value = false;
      toast.update(id, {
        render: "Error updating sub category.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false
      });
      errors.value =
        res?.response?.data && res?.response?.data?.errors
          ? res?.response?.data?.errors
          : {};
    });
};

onMounted(() => {
  ApiService.get(`sub-categories/${props.id}`)
    .then((res) => {
      category.value = res.data;
    })
    .catch(() => {
      toast.error("Error fetching data.");
    });
  getCategories(1, "all").then((res) => {
    categories.value = res.data;
  });
});
</script>

<style scoped></style>
