<template>
    <div class="flex justify-center h-screen px-1 py-24">
        <div class="flex items-center w-full h-fit lg:w-2/3" style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;">
            <div class="hidden md:w-1/2 md:block">
                <img src="@/assets/images/dashboard.svg" alt="dashboard photo">
            </div>
            <form @submit.prevent="login" class="w-full p-6 md:w-1/2">
                <h1 class="mb-8 text-3xl font-semibold text-center text-primary">{{ $t('actions.login') }}</h1>
                <div>
                    <label for="email" class="block mb-1">{{ $t('labels.email') }}</label>
                    <InputText
                        name="email"
                        type="email"
                        required
                        v-model="user.email"
                        class="w-full"
                        placeholder="example@gmail.com"
                    />
                    <p v-if="errors.email" class="mt-1 text-danger">{{ errors.email[0] }}</p>
                </div>
                <div class="mt-4">
                    <label for="password" class="block mb-1">{{ $t('labels.password') }}</label>
                    <InputText
                        name="password"
                        type="password"
                        required
                        v-model="user.password"
                        class="w-full"
                        placeholder=". . . ."
                    />
                    <p v-if="errors.password" class="mt-1 text-danger">{{ errors.password[0] }}</p>
                </div>
                <p v-if="errors.default" class="mt-1 text-danger">{{ errors.default }}</p>
                <button class="w-full py-3 mt-8 text-white transition-all duration-300 border rounded-lg bg-primary hover:bg-white border-primary hover:text-primary">{{ $t('actions.login') }}</button>
                <router-link :to="{name : 'RegisterView'}">
                    <button class="w-full py-3 mt-8 underline transition-all duration-300 rounded-lg">Doesn't Have an account? Register</button>
                </router-link>
            </form>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
// import { useRouter } from 'vue-router';
import InputText from "primevue/inputtext";
import ApiService from '@/services/ApiService';
import Cookies from 'js-cookie';
import { toast } from 'vue3-toastify';

// const router = useRouter();
const user = ref({
    email : '',
    password : '',
})
const errors = ref({});

const login = () => {
    const id = toast.loading('Logging in, please wait . . .');
    ApiService.post('login' , user.value).then((res) => {
        Cookies.set('token' , res.data.token , { expires: 7});
        Cookies.set('user' , JSON.stringify(res.data.user) , { expires: 7 });
        Cookies.set('roles' , JSON.stringify(res.data.roles) , {expires : 7});
        toast.update(id, {
            render: "Logged in successfully.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        const roles = [...res.data.roles];
        if (!roles.includes('Affiliate')) {
            window.location.assign('/dashboard/orders?page=1&perPage=10&delivery_status=ordered')
        } else {
            window.location.assign('/orders')
        }
        // router.push({name : 'OrdersView' , query : {page : 1 , delivery_status : ['ordered'] , perPage : 10}})
    }).catch((res) => {
        toast.update(id, {
            render: "Login failed.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
        errors.value = res.response?.data?.errors;
        setTimeout(() => {
            errors.value = {};
        } , 5000)
    })
}

</script>

<style scoped>

</style>
